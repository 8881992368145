.main{
	width: 100%;
	
	&__container{
		background: rgba(255, 255, 255, 0.65);
		border-radius: 20px;
		text-shadow: 1px 1px 2px rgb(139, 139, 139);
	}
	
	&__form{
		margin-bottom: 20px;
	}

}

.adventage{
	padding: 20px 0 20px 20px;

	&__item{
		display: flex;
		align-items: center;
		margin-bottom: 5px;
	}

	&__img{
		width: 50px;
		height: 50px;
	}
	&__text{
		padding-left: 40px;
		max-width: 358px;
	}
}