// Slider
.slider{

	&__wrapper{
		display: flex;
	}

	&__btn{
		width: 35px;
		cursor: pointer;
		&-next{
			background: url('../svg/next.svg') no-repeat center center;
			background-size: 30px 30px;
		}
		&-back{
			background: url('../svg/back.svg') no-repeat center center;
			background-size: 30px 30px;
		}
	}

	&__carousel{
		display: flex;
		justify-content: space-around;
		width: 100%;
	}

	&__item{
		display: none;
		width: 104px;
		// height: 65px;
		cursor: pointer;
		box-shadow: 1px 1px 2px;

		&:hover{
			box-shadow: 1px 1px 5px;
		}

		&-show{
			display: block;
		}

		&-active{
			box-shadow: 2px 2px 10px;
			&:hover{
				box-shadow: 2px 2px 10px;
			}
		}
	}

	&__description{
		margin-top: 5px;
		text-align: center;
	}


}

@media screen and (max-width:991px){
	.slider{
		&__item{
			width: 75px;
		}
		
	}
}
@media screen and (max-width:767px){
	.slider{
		&__item{
			width: 104px;
		}
		
	}
}
