@import "variables";
@import "mixins";


body{
	background: url("../img/collage.jpg") no-repeat;

	background-size: cover;
	
}

img{
	width: 100%;
}

h1{
	font-size:2.3rem;
	text-align: center;
	padding: 20px;
}

h4{
	text-align: center;
	font-size: 1.2rem;
	margin-bottom: 13px;
}
img{
	// width: 100%;
}
.mainContainer{
	min-height: 100vh;
	&__main{
		margin: 20px auto;
		max-width:920px;
	}
}
.myForm{
	max-width: 320px;
	
	padding: 20px;
	border-radius: 15px;

	&-y{
		background: rgba(255, 255, 0, 0.1);
	}

	&__title{
		font-weight: 600;
		text-align: center;
		font-size: 1.1rem;
		text-shadow: none;
	}

	&__img{
		width: 50px;
		height: 40px;
		margin: 0px auto;
		animation: myPointer 2s infinite cubic-bezier(0.15, 1.2, 0.64, -0.69);
	}
}

.my{
	&__border{
	&-b{
		border: 1px solid blue;
	}
	&-y{
		border: 1px solid yellow;
	}
	&-r{
		border: 1px solid red;
	}
	&-g{
		border: 1px solid green;
	}
	}
}

@keyframes myPointer{
	0%{
		background: url(../svg/pointers.svg) no-repeat center -30px;
		background-size: 15px 15px;
	}
	100%{
		background: url(../svg/pointers.svg) no-repeat center 40px;
		background-size: 30px 30px;
	}
}