.header{
	width: 100%;
	min-height: 100px;
	background: rgba(250, 250, 250, .65);
	display: flex;
	align-items: center;
	padding:10px;

	&__logo{
		max-width: 100px;
		text-align: right;
	}

	&__title{
		text-transform: uppercase;

	}

	&__action{

	}

	&__phone{
		color:$green;
		font-size: $bigFont;
		font-weight: 600;
		text-decoration: none;
		&:hover{
			color:$green;
			text-decoration: underline;
		}
	}
}