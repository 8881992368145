.footer{
	background: rgba(0,0,0, .5);
	width: 100%;
	min-height: 100px;

	display:flex;
	align-items: center;
	

	&__info{
		color: rgb(180, 179, 179);
		font-size: $smallFont;
	}

	&__link{
		text-decoration: none;

		&-mail{
			color: $green;
			font-size: $bigFont;
			text-decoration: none;
			&:hover{
				color: $green;
				text-decoration: underline;
			}
		}

		&-popup{
			color: rgb(180, 179, 179);
			font-size: $smallFont;
			text-decoration: none;
			&:hover{
				color: rgb(180, 179, 179);
				text-decoration: underline;
			}
		}
	}
}