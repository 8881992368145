.myModal{
	
	&__img{
		display: block;
		height: 70px;
		width: 70px;
		margin: 0 auto;

		&-orderCall{
			background: url(../svg/phone-call.svg) no-repeat center center;
			background-size: 70px 70px;
		}

		&-politica{
			background: url(../svg/file.svg) no-repeat center center;
			background-size: 70px 70px;
		}
		&-loading{
			background: url('../svg/reload.svg') no-repeat center center;
			background-size: 70px 70px;
		}

		&-success{
			background: url('../svg/checked.svg') no-repeat center center;
			background-size: 70px 70px;
		}

		&-failure{
			background: url('../svg/warning.svg') no-repeat center center;
			background-size: 70px 70px;
		}

	}

	&__form{
		max-width: 320px;
		margin: 0 auto;
	}

	&__header{
		text-align: center;
	}
	&__text{
		text-align: center;
	}
}